<template>
  <van-popup
    v-model="show"
    :position="position"
    get-container="body"
    :style="{ width: '100%', height: '100%' }"
    @opened="onOpened"
  >
    <van-nav-bar
      :title="$t('address.receiver_address')"
      left-arrow
      @click-left="$emit('close')"
    />
    <van-cell-group class="mt-3 mb-3 pt-1">
      <van-field
        label-width="0"
        :rows="5"
        type="textarea"
        v-model="addressText"
        :placeholder="parserAddressPlaceholder"
        @focus="parserAddressPlaceholder = $t('address.parse_address_focus_placeholder')"
        @blur="parserAddressPlaceholder = $t('address.parse_address_default_placeholder')"
      />
      <div class="text-center p-2">
        <p v-if="parsed" class="parsed_tips">
          {{ $t('address.parsed_tips') }}
        </p>
        <van-button class="mr-1" size="small" @click="addressText = '';parsed = false">{{ $t('address.parse_clear') }}</van-button>
<!--        <van-button type="primary" class="mr-1" size="small" @click="readClipboardy">读取剪贴板</van-button>-->
        <van-button type="primary" size="small" @click="parseAddress" :disabled="!addressText">{{ $t('address.parse_address') }}</van-button>
      </div>
    </van-cell-group>
    <van-form @submit="onSave" label-width="5rem">
      <van-cell-group class="van-address-edit">
        <van-field
          v-model="addressInfo.name"
          clearable
          :label="$t('receiver_address.name')"
          :placeholder="$t('receiver_address.placeholders.name')"
          :rules="[
            { required: true, message: $t('address.validate_messages.name') },
            { validator: realNameValidator, message: $t('address.validate_messages.realname_error') }
          ]"
        />
        <van-field :label="$t('address.phone_area_code')">
          <template #input>
            <van-radio-group v-model="area_code">
              <van-radio
                class="mb-1"
                v-for="item in phoneAreaCodes"
                :key="item.code"
                :name="item.code"
              >
                {{ item.code }}
              </van-radio>
              <van-radio name="">{{ $t('address.other_phone_area_code') }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          :label="$t('receiver_address.phone')"
          clickable
          type="tel"
          v-model="addressInfo.tel"
          :placeholder="$t('receiver_address.placeholders.phone')"
          :rules="[
            { required: true, message: $t('address.validate_messages.phone') },
            { validator: validatePhone, message: $t('address.validate_messages.phone_error') }
          ]"
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="districtText"
          :label="$t('address.area')"
          :placeholder="$t('address.placeholders.area')"
          @click="districtQueryString = null;showDistrictPicker = true"
          :rules="[{ required: true }]"
        />
        <van-field
          type="textarea"
          :rows="1"
          autosize
          clearable
          v-model="addressInfo.addressDetail"
          :label="$t('receiver_address.address_detail')"
          :placeholder="$t('receiver_address.placeholders.address_detail')"
          :rules="[{ required: true, message: $t('address.validate_messages.name') }]"
        />
        <van-field
          v-model="addressInfo.postalCode"
          label-width="5rem"
          :label="$t('address.zipcode')"
          :placeholder="$t('address.placeholders.zipcode')"
          :rules="[{ required: true, message: $t('address.placeholders.zipcode') }]"
        />
        <!--
        <van-field
          v-model="addressInfo.card_no"
          label-width="5rem"
          :label="$t('address.card_no')"
          :placeholder="$t('address.placeholders.card_no')"
          :rules="[{ required: true, message: $t('address.placeholders.card_no') }]"
        />
        <van-field
          label-width="7rem"
          :label="$t('address.card_file')">
          <template #input>
            <van-uploader
              v-model="addressInfo.card_file"
              :max-size="5242880"
              :max-count="1"
            />
          </template>
        </van-field>
        <van-field
          label-width="7rem"
          :label="$t('address.opposite_file')">
          <template #input>
            <van-uploader
              v-model="addressInfo.opposite_file"
              :max-size="5242880"
              :max-count="1"
            />
          </template>
        </van-field>
        -->
        <van-field :label="$t('receiver_address.isDefault')" label-width="8rem">
          <template #input>
            <van-switch v-model="addressInfo.isDefault" size="24px" />
          </template>
        </van-field>
        <div class="van-address-edit__buttons">
          <van-button type="danger" block round>{{ $t('common.save') }}</van-button>
          <van-button type="default" block round @click.prevent="onDelete(addressInfo.id)" v-if="addressInfo.id">{{ $t('common.delete') }}</van-button>
        </div>

        <div class="open-wx-address" v-if="openAddressApi">
          <van-button type="primary" round block @click="wxOpenAddress">{{ $t('address.share_wx_address') }}</van-button>
        </div>
      </van-cell-group>
    </van-form>
    <van-popup v-model="showDistrictPicker" position="bottom" get-container="#app" round>
      <van-picker
        ref="districtPicker"
        show-toolbar
        :columns="districtList"
        value-key="name"
        @confirm="districtConfirmed"
        @cancel="showDistrictPicker = false"
      >
        <template #columns-top>
          <van-search
            v-model="districtQueryString"
            show-action
            input-align="center"
            :placeholder="$t('order.district_filter')"
            @search="onSearchDistrict"
            @clear="onClearDistrict"
          >
            <template #action>
              <span @click="onSearchDistrict">{{ $t('common.search') }}</span>
            </template>
          </van-search>
        </template>
      </van-picker>
    </van-popup>
    <van-popup v-model="showDistrictFilterPicker" position="bottom" get-container="#app" round>
      <van-picker
          show-toolbar
          :columns="districtFilteredList"
          :value-key="locale === 'zh_HK' ? 'tc_name' : 'ts_name'"
          @confirm="chooseDistrict"
          @cancel="showDistrictFilterPicker = false"
      >
        <template #columns-top>
          <van-search
            v-model="districtQueryString"
            show-action
            input-align="center"
            :placeholder="$t('order.district_filter')"
            @search="onSearchDistrict"
            @clear="onClearDistrict"
          >
            <template #action>
              <span @click="onSearchDistrict">{{ $t('common.search') }}</span>
            </template>
          </van-search>
        </template>
      </van-picker>
    </van-popup>
  </van-popup>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
// import smartParse from 'address-smart-parse';
import { mapState } from 'vuex';
import { Uploader } from 'vant';
import {
  phoneValidator,
  realNameValidator,
  showErrorMessage,
  Probability,
} from '@/helpers';

Vue.use(Uploader);

/* global wx */

const clipboardy = require('clipboardy');

export default {
  name: 'ReceiverAddress',
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    editId: Number,
    position: {
      type: String,
      default: 'right',
    },
  },
  data() {
    return {
      districtQueryString: '',
      showDistrictFilterPicker: false,
      districtFilteredList: [],
      locale: localStorage.getItem('locale'),
      openAddressApi: false,
      area_code: '+852',
      parsed: false,
      addressText: '',
      showDistrictPicker: false,
      districtText: '',
      district: null,
      parserAddressPlaceholder: this.$t('address.parse_address_default_placeholder'),
      addressInfo: {
        name: '',
        tel: '',
        postalCode: '',
        addressDetail: '',
        isDefault: false,
        card_no: '',
        card_file: [],
        opposite_file: [],
      },
    };
  },
  mounted() {
    this.$api.app.jsSdk().then(({ data: response }) => {
      wx.config({
        debug: response.debug,
        appId: response.appId,
        timestamp: response.timestamp,
        nonceStr: response.nonceStr,
        signature: response.signature,
        jsApiList: response.jsApiList,
      });
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ['openAddress'],
          success: ({ checkResult }) => {
            this.openAddressApi = checkResult.openAddress;
          },
        });
      });
    });
  },
  computed: {
    ...mapState({
      phoneAreaCodes: state => state.app.phoneAreaCodes,
      address: state => state.address.receiverAddress,
      districtList: state => state.address.districtList,
      districtFullPathName: state => state.address.districtFullPathName,
    }),
  },
  methods: {
    onSearchDistrict() {
      if (this.districtQueryString) {
        this.districtFilteredList = this.districtFullPathName.filter(item => ~item.tc_name.indexOf(this.districtQueryString) || ~item.ts_name.indexOf(this.districtQueryString));
        this.showDistrictPicker = false;
        this.showDistrictFilterPicker = true;
      } else {
        this.onClearDistrict();
      }
    },
    onClearDistrict() {
      this.showDistrictPicker = true;
      this.showDistrictFilterPicker = false;
    },
    chooseDistrict(value, indexes) {
      if (value.node[0]) {
        const countries = this.$refs.districtPicker.getColumnValues(0);
        this.$refs.districtPicker.setColumnIndex(0, _.findIndex(countries, { id: Number(value.node[0]) }));
      }
      if (value.node[1]) {
        const provinces = this.$refs.districtPicker.getColumnValues(1);
        this.$refs.districtPicker.setColumnIndex(1, _.findIndex(provinces, { id: Number(value.node[1]) }));
      }
      if (value.node[2]) {
        const cities = this.$refs.districtPicker.getColumnValues(2);
        this.$refs.districtPicker.setColumnIndex(2, _.findIndex(cities, { id: Number(value.node[2]) }));
      }
      if (value.node[3]) {
        const regions = this.$refs.districtPicker.getColumnValues(3);
        this.$refs.districtPicker.setColumnIndex(3, _.findIndex(regions, { id: Number(value.node[3]) }));
      }

      this.district_id = value.node[value.node.length - 1];
      this.addressInfo.postalCode = value.zipcode;
      this.districtText = this.locale === 'zh-HK' ? value.tc_name : value.ts_name;
      this.showDistrictFilterPicker = false;
    },
    realNameValidator,
    validatePhone() {
      const areaCode = _.find(this.phoneAreaCodes, { code: this.area_code });
      return phoneValidator(`${this.area_code}${this.addressInfo.tel}`, areaCode ? areaCode.countryCode : null);
    },
    readClipboardy() {
      clipboardy.read().then((text) => {
        this.addressText = text;
      }).catch(() => {
      });
    },
    parseAddress() {
      if (this.addressText) {
        this.$api.app.parseAddress({ address: this.addressText }).then(({ data: response }) => {
          // console.log(response);
          const {
            name, mobile, zipcode, province, city, region, street,
          } = response;
          if (name) {
            this.addressInfo.name = name;
          }

          if (mobile) {
            // todo
            if (/^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/.test(mobile)) {
              this.area_code = '+86';
            } else if (/8[0-46-9]\d{6,7}|9\d{4}(?:\d(?:\d(?:\d{4})?)?)?|(?:[235-79]\d|46)\d{6}/.test(mobile)) {
              this.area_code = '+852';
            } else if (/(?:28|[68]\d)\d{6}/.test(mobile)) {
              this.area_code = '+853';
            } else if (/[2-689]\d{8}|7\d{9,10}|[2-8]\d{7}|2\d{6}/.test(mobile)) {
              this.area_code = '+886';
            } else {
              this.area_code = '';
            }
            this.addressInfo.tel = mobile;
          }

          if (street) {
            this.addressInfo.addressDetail = street;
          }

          if (zipcode) {
            this.addressInfo.postalCode = zipcode;
          }
          this.districtText = `${province.name} ${city.name} `;
          if (region) {
            this.district_id = region.id;
            this.districtText += region.name;
          }
        }).catch((e) => {
          showErrorMessage(e);
        }).finally(() => {
          this.parsed = true;
          this.$toast.clear();
        });
      }
      // return;

      //   this.parsed = true;
      // }
    },
    beforeRead(file) {
      if (!/image\//.test(file.type)) {
        this.$toast(this.$t('address.please_upload_image'));
        return false;
      }
      return true;
    },
    districtConfirmed(values, indexes) {
      const valueFiltered = _.filter(values);
      const districts = this.$refs.districtPicker.getColumnValues(valueFiltered.length - 1);
      const index = indexes[valueFiltered.length - 1];
      this.district_id = districts[index].id;
      this.addressInfo.postalCode = districts[index].zipcode;
      this.districtText = values.join(' ');
      this.showDistrictPicker = false;
    },
    onSave() {
      if (!this.addressInfo.name) {
        this.$toast.fail(this.$t('address.validate_messages.name'));
        return false;
      }
      if (!this.addressInfo.addressDetail) {
        this.$toast.fail(this.$t('address.validate_messages.address'));
        return false;
      }
      if (!this.addressInfo.tel) {
        this.$toast.fail(this.$t('address.validate_messages.phone'));
        return false;
      }
      if (!this.addressInfo.postalCode) {
        this.$toast.fail(this.$t('address.validate_messages.zipcode'));
        return false;
      }
      /*
      if (!this.addressInfo.card_no) {
        this.$toast.fail(this.$t('address.validate_messages.card_no'));
        return false;
      }
      if (!this.addressInfo.card_file.length) {
        this.$toast.fail(this.$t('address.validate_messages.card_file'));
        return false;
      }
      if (!this.addressInfo.opposite_file.length) {
        this.$toast.fail(this.$t('address.validate_messages.opposite_file'));
        return false;
      }
       */
      if (!this.district_id) {
        this.$toast.fail(this.$t('address.validate_messages.district'));
        return false;
      }
      const fd = new FormData();
      fd.append('name', this.addressInfo.name);
      fd.append('phone', `${this.area_code} ${this.addressInfo.tel}`);
      fd.append('address', this.addressInfo.addressDetail);
      fd.append('zipcode', this.addressInfo.postalCode);
      fd.append('default', Number(this.addressInfo.isDefault));
      fd.append('district_id', this.district_id);
      if (this.addressInfo.card_no) {
        fd.append('card_no', this.addressInfo.card_no);
      }
      if (this.addressInfo.card_file[0]) {
        fd.append('card_file', this.addressInfo.card_file[0].file);
      }
      if (this.addressInfo.opposite_file[0]) {
        fd.append('opposite_file', this.addressInfo.opposite_file[0].file);
      }
      if (this.editId) {
        fd.append('id', this.editId);
      }
      this.$toast.loading({
        message: this.$t('common.saving'),
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      this.$api.address.receiver.save(fd).then(({ data: response }) => {
        this.$toast.success(this.$t('common.saved'));
        this.$emit('saved', response.data);
        this.$emit('close');
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        this.$toast.clear();
      });
      return true;
    },
    onDelete(id) {
      this.$dialog.confirm({
        message: this.$t('address.delete_comfirm'),
      }).then(() => {
        this.$store.dispatch('deleteReceiverAddress', id).then(() => {
          this.$toast.success(this.$t('common.deleted'));
          this.$emit('deleted', id);
          this.$emit('close');
        });
      }).catch(() => {});
    },
    onOpened() {
      (new Probability({
        f: () => this.$store.dispatch('fetchDistrict'),
        p: this.districtList.length ? '50%' : '100%',
      }))();
      this.$store.dispatch('fetchDistrictFullPathName');

      if (this.editId) {
        const address = _.find(this.address, { id: this.editId });
        if (address) {
          this.phoneAreaCodes.every(({ code }) => {
            if (address.phone.startsWith(code)) {
              this.area_code = code;
              return false;
            }
            return true;
          });
          // this.addressInfo = {
          //   card_file: [],
          //   opposite_file: [],
          // };
          this.addressInfo.id = address.id;
          this.addressInfo.name = address.name;
          this.addressInfo.tel = address.phone.replace(this.area_code, '').trim();
          this.addressInfo.addressDetail = address.address;
          this.addressInfo.postalCode = address.zipcode;
          this.addressInfo.isDefault = Boolean(address.default);
          this.district_id = address.district_id;
          this.districtText = address.district_fullname;
          this.addressInfo.card_no = address.card_no;
          if (address.card_file_path) {
            this.addressInfo.card_file = [{ url: address.card_file_path, isImage: true }];
          }
          if (address.opposite_file_path) {
            this.addressInfo.opposite_file = [{ url: address.opposite_file_path, isImage: true }];
          }
        }
      } else {
        // init
        this.addressText = '';
        this.district = null;
        this.addressInfo = {
          name: '',
          tel: '',
          addressDetail: '',
          isDefault: false,
        };
        this.parsed = false;
        this.addressText = '';
        this.districtText = '';
        this.district = null;
        this.parserAddressPlaceholder = this.$t('address.parse_address_default_placeholder');
        this.addressInfo = {
          name: '',
          tel: '',
          postalCode: '',
          addressDetail: '',
          isDefault: false,
          card_no: '',
          card_file: [],
          opposite_file: [],
        };
      }
    },
    wxOpenAddress() {
      wx.openAddress({
        success: (res) => {
          this.parseWxAddressInfo(res);
        },
      });
    },
    parseWxAddressInfo(res) {
      // eslint-disable-next-line
      // res = {"nationalCode":"","telNumber":"13602414197","userName":"赖伟杰","errMsg":"openAddress:ok","postalCode":"510655","provinceName":"广东省","cityName":"广州市","countryName":"天河区","detailInfo":"华江花园(广州市天河区)A3栋801"};
      const {
        userName,
        postalCode,
        telNumber,
        provinceName,
        cityName,
        countryName,
        detailInfo,
      } = res;

      if (userName) {
        this.addressInfo.name = userName;
      }
      if (detailInfo) {
        this.addressInfo.addressDetail = detailInfo;
      }
      if (postalCode) {
        this.addressInfo.postalCode = postalCode;
      }
      if (telNumber) {
        if (/1[127]\d{8,9}|2\d{9}(?:\d{2})?|[12]\d{6,7}|86\d{6}|(?:1[03-689]\d|6)\d{7,9}|(?:[3-579]\d|8[0-57-9])\d{6,9}/.test(telNumber)) {
          this.area_code = '+86';
        } else if (/8[0-46-9]\d{6,7}|9\d{4}(?:\d(?:\d(?:\d{4})?)?)?|(?:[235-79]\d|46)\d{6}/.test(telNumber)) {
          this.area_code = '+852';
        } else if (/(?:28|[68]\d)\d{6}/.test(telNumber)) {
          this.area_code = '+853';
        } else if (/[2-689]\d{8}|7\d{9,10}|[2-8]\d{7}|2\d{6}/.test(telNumber)) {
          this.area_code = '+886';
        } else {
          this.area_code = '';
        }
        this.addressInfo.tel = telNumber;
      }
      if (provinceName && cityName && countryName) {
        const districtTextArr = [];
        const chinaDistrict = this.districtList[0];
        districtTextArr.push(chinaDistrict.name);
        const $province = _.find(chinaDistrict.children, item => ~item.name.indexOf(provinceName.replace(/省|市/, '')));
        this.district_id = $province.id;
        if ($province) {
          districtTextArr.push($province.name);
          const $city = _.find($province.children, item => ~item.name.indexOf(cityName.replace('市', '')));
          this.district_id = $city.id;
          if ($city) {
            districtTextArr.push($city.name);
            const $county = _.find($city.children, item => ~item.name.indexOf(countryName.replace('区', '')));
            if ($county) {
              districtTextArr.push($county.name);
              this.district_id = $county.id;
            }
          }
        }
        this.districtText = districtTextArr.join(' ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .parsed_tips {
    font-size: .5rem;
  }

  .van-address-edit__buttons {
    padding-bottom: 10px;
  }
</style>
