import { render, staticRenderFns } from "./SenderAddressPopup.vue?vue&type=template&id=025b68d2&scoped=true&"
import script from "./SenderAddressPopup.vue?vue&type=script&lang=js&"
export * from "./SenderAddressPopup.vue?vue&type=script&lang=js&"
import style0 from "./SenderAddressPopup.vue?vue&type=style&index=0&id=025b68d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "025b68d2",
  null
  
)

export default component.exports