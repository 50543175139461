<template>
  <div class="address-list" :class="type">
    <van-address-list
      ref="vanAddressList"
      v-model="chosenAddressId"
      :list="list"
      :disabled-list="disabledList"
      :disabled-text="disabledText"
      :switchable="switchable"
      :default-tag-text="$t('common.default')"
      :add-button-text="$t('address.create')"
      @add="onAdd"
      @edit="onEdit"
      @select="(item, index) => $emit('select', item, index)"
      @click-item="(item, index) => $emit('click-item', item, index)"
    />
    <sender-address-popup
      v-model="showSenderAddressPopup"
      @close="showSenderAddressPopup = false"
      @saved="fetchData"
      @deleted="onDeleted"
      :edit-id="editId"
    />
    <receiver-address-popup
      v-model="showReceiverAddressPopup"
      @close="showReceiverAddressPopup = false"
      @saved="fetchData"
      @deleted="onDeleted"
      :edit-id="editId"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  NavBar, Tab, Tabs, AddressList,
} from 'vant';
import SenderAddressPopup from '@/components/address/SenderAddressPopup';
import ReceiverAddressPopup from '@/components/address/ReceiverAddressPopup';

Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(AddressList);

export default {
  name: 'AddressList',
  props: {
    districtId: {
      required: false,
    },
    type: {
      required: true,
    },
    switchable: {
      type: Boolean,
      default: true,
    },
    disabledText: {
      type: String,
      default: '',
    },
    enableFilterFunc: Function,
  },
  data() {
    return {
      editId: null,
      showSenderAddressPopup: false,
      showReceiverAddressPopup: false,
      chosenAddressId: null,
      list: [],
      disabledList: [],
    };
  },
  watch: {
    chosenAddressId(val) {
      this.$emit('checked', val);
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState({
      senderAddress: state => state.address.senderAddress,
      receiverAddress: state => state.address.receiverAddress,
    }),
  },
  methods: {
    fetchData() {
      this.list = [];
      let params = {};
      if (this.districtId) {
        params = {
          district_id: this.districtId,
        };
      }
      if (this.type === 'sender') {
        this.$toast.loading({
          message: this.$t('common.loading'),
          forbidClick: true,
          duration: 0,
        });
        this.$store.dispatch('fetchSenderAddress').then(() => {
          this.renderList(this.senderAddress);
        }).finally(() => {
          this.$toast.clear();
        });
      }
      if (this.type === 'receiver') {
        this.$toast.loading({
          message: this.$t('common.loading'),
          forbidClick: true,
          duration: 0,
        });
        this.$store.dispatch('fetchReceiverAddress', params).then(() => {
          this.renderList(this.receiverAddress);
        }).finally(() => {
          this.$toast.clear();
        });
      }
    },
    renderList(addressList) {
      addressList.forEach((address) => {
        const fullAddress = address.district_fullname ? `${address.district_fullname} ${address.address}` : address.address;
        if (!this.enableFilterFunc || this.enableFilterFunc(address)) {
          this.list.push({
            id: address.id,
            name: address.name,
            address: fullAddress,
            tel: address.phone,
            hasCardInfo: Boolean(address.card_file && address.opposite_file),
            // isDefault: Boolean(address.default),
          });
        } else {
          this.disabledList.push({
            id: address.id,
            name: address.name,
            address: fullAddress,
            tel: address.phone,
            hasCardInfo: Boolean(address.card_file && address.opposite_file),
            // isDefault: Boolean(address.default),
          });
        }
      });
      // const defaultItemIndex = _.findIndex(this.list, item => item.isDefault);
      // if (~defaultItemIndex) {
      //   this.chosenAddressId = this.list[defaultItemIndex].id;
      // }
    },
    onAdd() {
      if (this.type === 'sender') {
        this.showSenderAddressPopup = true;
      } else {
        this.showReceiverAddressPopup = true;
      }
      this.editId = null;
    },
    onEdit(address) {
      this.editId = address.id;
      if (this.type === 'sender') {
        this.showSenderAddressPopup = true;
      } else {
        this.showReceiverAddressPopup = true;
      }
    },
    onDeleted(id) {
      this.list = _.reject(this.list, { id });
      this.$emit('deleted', id);
    },
  },
  components: {
    SenderAddressPopup,
    ReceiverAddressPopup,
  },
};
</script>

<style lang="scss">
  .address-list {
  }
</style>
